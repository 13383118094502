import React from "react";
import { Button } from "../../Components/Button";

import "./HomeHeader.scss";

export const HomeHeader = () => {
  return (
    <div className="HomeHeader">
      <div className="HomeHeader__left">
        <div className="HomeHeader__left__title">
          Transfer UNFI from Binance Smart Chain to Ethereum
        </div>
        <div className="HomeHeader__left__body">
          BEP20 UNFI tokens on BSC can be deposited on this page and then
          transferred to Ethereum via the transfer bridge. You must input an
          Ethereum address for which you own the private key. Do not use an
          exchange wallet. If you make a typo, or paste the wrong address, or
          input a BSC address, your funds may be lost.
          <br />
          No refunds are possible for any reason. Unifi is not responsible for
          any loss, regardless of cause.
          <p>Instructions:</p>
          <ol>
            <li> Connect to Binance Smart Chain with a supported wallet.</li>
            <li>
              Enter an amount of BEP20 UNFI tokens to transfer to Ethereum.
            </li>
            <li>
              Enter an appropriate Ethereum address. See above for important
              details.
            </li>
            <li>Press the Transfer UNFI button. Network fees apply.</li>
            <li>Go to Claim page for ERC20 tokens.</li>
          </ol>
        </div>
        <div className="HomeHeader__left__more-info">
          <a
            href="https://gov.unifiprotocol.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button onClick={() => {}}>UNFI Staking on Ethereum</Button>
          </a>
        </div>
      </div>
      <div className="HomeHeader__right hide-for-mobile"></div>
    </div>
  );
};
