import React from "react";
import { Button } from "../../Components/Button";
// import { Deposit } from "../../Components/Deposit";

import "./Home.scss";

export const Home = () => {
  return (
    <div className="Home">
      <div className="Home__items">
        {/* <Deposit /> */}
        <div className="Home__items__item">
          <div className="Home__items__item__title">Claim UNFI</div>
          <div>
            <p>Click the button below to claim your UNFI tokens on Ethereum.</p>
            <p>
              The tokens will be made claimable within 7 days after the Unifi
              Protocol BSC to ETH event has concluded.
            </p>
          </div>
          <div className="Home__items__item__submit">
            <Button
              onClick={() =>
                window.open("https://gov.unifiprotocol.com/my-dashboard")
              }
            >
              Go to Claim page
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
