export enum ContractMethod {
  TRANSFER = "transfer",
  BALANCE_OF = "balanceOf",
  APPROVE = "approve",
  GET_ALLOWANCE = "getAllowance",
  TOTAL_STAKE_AMOUNT = "totalStakeAmount",
  USER_STAKE_AMOUNT = "userStakeAmount",
  CONTRACT_TOTAL_CLAIMED = "contractTotalClaimed",
  STAKE = "stake",
  UNSTAKE = "unstake",
  CLAIM = "claim",
  REWARD_RATE = "rewardRate",
  TOTAL_CLAIMED = "totalClaimed",
  PENDING_CLAIM = "stakePendingReward",
  PERIOD_FINISH = "periodFinish",
  DEPOSIT = "deposit",
  DEPOSIT_UNFI_TOKEN = "depositUNFIToken",
  UNFI_DEPOSITED_OF = "unfiDepositedOf",
}
