export const Config = {
  blockchain: "binance",
  blockchainToken: "BNB",
  transactionExplorer: "https://bscscan.com/tx/{{TRANSACTION_HASH}}",
  accountExplorer: "https://bscscan.com/address/{{ADDRESS}}",
  depositContract: {
    address: "0x11C7DdDce2C0F9974a9c78EC7D246629fe004445",
    decimals: 18,
  },
  contracts: {
    UNFI: {
      address: "0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B",
      decimals: 18,
    },
    BNB: {
      address: "BNB",
      decimals: 18,
    },
  },
  globalPrecision: Math.pow(10, 18),
  globalPowerPrecision: 18,
  percentagePrecision: 100000,
  numberLocale: "en-US",
};
